import admin from './admin'
import dashboard from './dashboard'
import products from './products'
import operations from './operations'
import reporting from './reporting'
import setups from './setups'
import account from './account'
import warehouse from './warehouse'

import { getUserData } from '@/auth/utils'
const userData = getUserData()

let nav = []
if(userData.role_asset === 'admin'){
    nav = [...admin, ...warehouse, ...account]
}else{
    // nav = [...dashboard, ...products, ...operations, ...reporting, ...setups]
    nav = [...dashboard, ...products, ...operations, ...setups]
}
//nav = [...dashboard, ...warehouse, ...account]

// Array of sections
export default nav //[...dashboard, ...warehouse, ...account]
