<template>
  <b-card-code
    title="Tambah Akun"
  >
  <b-card-body>
        <b-row class="my-2">
          
            <!-- Left: Form Input -->
            <b-col
              cols="12"
              md="5"
            >   
              <b-form @submit.prevent="created">           
                <b-form-group>
                  <b-card-text class="mb-0"> {{ $t('Form.Account.Name') }} </b-card-text>
                  <b-form-input
                    v-model="name"
                    id="mc-first-name"
                    placeholder="Name"
                  />
                </b-form-group>
                <b-form-group>
                  <b-card-text class="mb-0"> {{ $t('Form.Account.Email') }} </b-card-text>
                  <b-form-input
                    v-model="email"
                    id="mc-last-name"
                    placeholder="Email"
                  />
                </b-form-group>
                <b-form-group>
                  <b-card-text class="mb-0"> {{ $t('Form.Account.Password') }} </b-card-text>
                  <b-form-input
                    v-model="password"
                    
                    id="password"
                    :disabled="disabledPassword"
                    placeholder="Password"
                  />
                </b-form-group>
              <!-- submit and reset -->
              </b-form>
            </b-col>

            <!-- Right: Product Details -->
            <b-col
              cols="12"
              md="7"
            >
              <b-form-group v-if="userData.role_asset === 'admin'">
                <b-card-text class="mb-0"> {{ $t('Form.Account.Roles') }} </b-card-text>
                <v-select
                  v-model="roles"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="rolesOptions"
                  label="title"
                />
              </b-form-group>
              <b-form-group v-if="userData.role_asset === 'admin'">
                <b-card-text class="mb-0"> {{ $t('Form.Account.Instansi') }} </b-card-text>
                <v-select
                  v-model="instansi"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="instansiOptions"
                  :reduce="(option) => option.id"
                  @input="fetchDataListWarehouse()"
                  label="name"
                />
              </b-form-group>
              <b-form-group v-if="userData.role_asset === 'admin'">
                <b-card-text class="mb-0"> {{ $t('Form.Account.Warehouse') }} </b-card-text>
                <v-select
                  v-model="warehouse"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="warehouseOptions"
                  multiple
                  label="name"
                />
              </b-form-group>
              <div
                class="color-option"
              >
                <div
                  class="filloption"
                />
              </div>

              <hr>
              <b-col
                cols="12"
                md="6"
              >         
                <b-card-text class="mb-0">
                {{ $t('Form.Account.Status') }}
                </b-card-text>
                <b-form-checkbox
                    v-model="status"
                    checked="true"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                />
              </b-col>

              <div class="d-flex flex-column flex-sm-row pt-1">             
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="created"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                  />
                  <span>{{ $t('Submit') }}</span>
                </b-button>
              </div>
            </b-col>        
        </b-row>
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadioGroup, BInputGroup, BCardText, BCardBody
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { getUserData } from '@/auth/utils' 

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    BInputGroup,
    vSelect,
    BCardText,
    BCardBody
  },
  directives: {
    Ripple,
  },

  data: () => ({
    id: '',
    name: '',
    email: '',
    password: '',
    roles: '',
    warehouse: '',
    instansi: '',
    status: true,
    warehouseOptions: [],
    instansiOptions: [],
    options: [
      { text: 'Active', value: '1' },
      { text: 'Inactive', value: '0' },
    ],
    characters: 'a-z,A-Z,0-9,#',
    size: 24,
    rolesOptions: [{ title: 'Admin', id:'admin' }, { title: 'Klien', id:'user' }],
    userData: getUserData(),
    disabledPassword: false,
  }),
  methods: {
    fetchDataAccount(id) { 
      moduleApi.fetchDataAccountByIdSearch({ id })
      .then(response => {
          const user = response.data.data[0]
          this.disabledPassword = true
          this.id = user.id
          this.name = user.name
          this.email = user.email
          this.password = 'Tidak Diberi Akses Merubah'
          this.roles = user.role
          this.instansi = user.id_instansi
          this.status = user.active
      })
      .catch(error => {
        this.$refs.loginForm.setErrors(error.response.data.error)
      })
    },
    fetchDataListWarehouse() { 
      this.warehouse = ''
      moduleApi.fetchDataWarehouse(this.instansi)
        .then(response => {
            this.warehouseOptions = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
      })
    },
    fetchDataListInstansi() { 
      moduleApi.fetchDataCustomers()
      .then(response => {
          this.instansiOptions  = response.data.data
      })
      .catch(error => {
        this.$refs.loginForm.setErrors(error.response.data.error)
      })
    },
    generate () {
      let charactersArray = this.characters.split(',');  
      let CharacterSet = '';
      let password = '';
      
        if( charactersArray.indexOf('a-z') >= 0) {
          CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
        }
        if( charactersArray.indexOf('A-Z') >= 0) {
          CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        }
        if( charactersArray.indexOf('0-9') >= 0) {
          CharacterSet += '0123456789';
        }
        if( charactersArray.indexOf('#') >= 0) {
          CharacterSet += '![]{}()%&*$#^<>~@|';
        }
      
        for(let i=0; i < this.size; i++) {
          password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
        }
      this.password = password;
    },
    created() { 
      if(this.$route.params.id === undefined){
        moduleApi.createAccountwarehouse({
          name: this.name,
          email: this.email,
          password: this.password,
          roles: this.roles.id,
          instansi: this.instansi,
          warehouse: this.warehouse,
          status: this.status
        })
        .then(response => {
            this.$router.replace({name: 'setups-account'})
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        moduleApi.updatedAccount({
          id: this.$route.params.id,
          name: this.name,
          email: this.email,
          status: this.status,
          updated_at: new Date().toISOString()
        })
        .then(response => {
            this.$router.replace({name: 'setups-account'})
        })
        .catch(error => {
          console.log(error)
        })
      }
    },
  },
  created () {
    this.fetchDataListInstansi()
    if(this.userData.role_asset === 'user'){
      this.roles = { title: 'Klien', id:'user' }
      this.instansi = this.userData.idInstansi
      this.fetchDataListWarehouse()
    }

    if(this.$route.params.id !== undefined){
      this.fetchDataAccount(this.$route.params.id)
    }

    this.generate();
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
