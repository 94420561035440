<template>
  <b-navbar-nav class="nav-item nav-search">
    <!-- Icons -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      v-b-modal.modal-warehouse
    >
      <feather-icon
        icon="HomeIcon"
        size="21"
      /> More ...
    </a>

    <!-- Modal -->
    <b-modal
      id="modal-warehouse"
      centered
      ref="warehouse-modal"
      title="Penempatan"
      hide-footer
      size="lg"
    >
      <div
        v-for="(row, index) in warehouse"
        :key="index"
        class="warehouse"
      >
        <b-dropdown-item href="#" 
          :style="selected === row.id ? {'background-color': '#74f7ad'} : {'background-color': '#f1f1f1'}" 
          @click="onChangeWarehouse(row)">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <img src="@/assets/images/illustration/warehouse.png" alt="Warehouse" width="50" height="50">
            </b-media-aside>
            <b-media-body>
              <h6 class="align-self-center my-auto">
                {{ row.name }}
              </h6>
            </b-media-body>
          </b-media>
        </b-dropdown-item>
      </div>
    </b-modal>
  </b-navbar-nav>
</template> 

<script>
import {
  BFormInput, BLink, BImg, BAvatar, BCardText, BModal, VBModal, BMedia, BMediaAside, BMediaBody, BDropdownItem, BNavbarNav
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import moduleApi from '@/store/module'

export default {
  components: {
    BFormInput,
    BLink,
    BImg,
    BAvatar,
    BCardText,
    BModal, 
    VBModal,
    BMedia, 
    BMediaAside, 
    BMediaBody,
    BDropdownItem,
    BNavbarNav
  },
  data: () => ({
    localStorageWarehouse: JSON.parse(localStorage.getItem("warehouse")),
    selected: 'default',
    warehouse: []
  }),
  methods: {
    fetchDataListWarehouse(idInstansi) { 
      moduleApi.fetchDataWarehouse(idInstansi)
        .then(response => { 
            this.warehouse = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },

    onChangeWarehouse(row) {
      this.selected = row.id
      localStorage.setItem('warehouse', JSON.stringify(row))
      this.$refs['warehouse-modal'].toggle('#toggle-btn')
      location.reload();
    },
  },
  created () {
    const userData = getUserData()
    this.idInstansi = userData.idInstansi

    this.fetchDataListWarehouse(this.idInstansi)
    this.selected = this.localStorageWarehouse.id;
  },
}
</script>

<style lang="scss">
  .chip {
    display: inline-block;
    padding: 0 28px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    border-radius: 25px;
    color: #000;
  }

  .chip img {
    float: left;
    margin: 0 10px 0 -25px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .closebtn {
    cursor: pointer;
  }
</style>
