<template>
  <!-- <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      >
        <analytics-congratulation :data="data.congratulations" />
      </b-col>

      <b-col
        lg="6"
        sm="6"
      >
      <b-card
      no-body
      >
      <b-card-header class="pb-0">
      </b-card-header>
      <b-card-body>
        <b-row sm="2">
          <app-timeline>
            <app-timeline-item
              title="Pencatatan & Pelacakan"
              subtitle="Mejadi poin penting dalam apps"
              icon="ClockIcon"
              variant="success"
            />

            <app-timeline-item
              title="Organisasi Gudang"
              subtitle="Memastikan gudang terorganisir dengan baik"
              icon="PackageIcon"
              variant="success"
            />

            <app-timeline-item
              title="Pengaturan Stok"
              subtitle="Perencanaan stok yang tepat berdasarkan analisis"
              icon="ToolIcon"
              variant="success"
            />

            <app-timeline-item
              title="Analisis Kinerja"
              subtitle="Analisis kinerja secara berkala sebagai dasar evaluasi"
              icon="DatabaseIcon"
            />

            <app-timeline-item
              title="Integrasi Sistem"
              subtitle="Integrasi sistem manajemen gudang dengan sistem manajemen lainnya"
              icon="RefreshCwIcon"
              variant="success"
            />
          </app-timeline>
        </b-row>
      </b-card-body>
    </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="9">
        <analytics-avg-sessions :data="data.avgSessions" />
      </b-col>
      <b-col lg="3">
        <analytics-support-tracker/>
      </b-col>
    </b-row>
  </section> -->
</template> 

<script>
import { BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol } from 'bootstrap-vue'

// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
// import { kFormatter } from '@core/utils/filter'
// import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
// import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
// import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
// import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'


export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    // AnalyticsCongratulation,
    // AnalyticsAvgSessions,
    // StatisticCardWithAreaChart,
    // AnalyticsSupportTracker,
    // AppTimeline,
    // AppTimelineItem,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // this.$http.get('/analytics/data')
    //   .then(response => { this.data = response.data })
  },
  methods: {
    // kFormatter,
  },
}
</script>
