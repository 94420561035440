import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import admin from './routes/admin'
import dashboard from './routes/dashboard'
import apps from './routes/apps'
import operations from './routes/operations'
import reporting from './routes/reporting'
import setups from './routes/setups'
import warehouse from './routes/warehouse'

import pages from './routes/pages'

import keycloak from "../keycloak";

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', 
      redirect: to => {
        const userData = JSON.parse(localStorage.getItem('userData') || '{}')
        const userRole = (userData && userData.role_asset) ? userData.role_asset : null
        if (userRole === 'admin')
          return { name: 'admin-dashboard' }
        if (userRole === 'user')
          return { name: 'dashboard' }
        
        return { name: 'dashboard', query: to.query }
      },
    },
    ...dashboard,
    ...apps,
    ...operations,
    ...setups,
    ...reporting,
    ...pages,
    ...warehouse,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  // const isLoggedIn = isUserLoggedIn()
  // if (!canNavigate(to)) {
  //   if (!isLoggedIn) return next({ name: 'auth-login' })

  //   return next({ name: 'misc-not-authorized' })
  // }

  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }

  // return next()

  if (to.meta.requiresAuth) {
    if (keycloak.authenticated) {
      canNavigate(to)
      if (to.meta.redirectIfLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role_asset : null))
      }
      
    } else {
      keycloak.login();
    }
  } else {
    next();
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
