<template>
    <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="12">
        <div id="streamlit_container">
          <!-- <iframe
            src="https://cors-proxy.fringe.zone/http://8.215.32.58:8501"
            width="100%"
            height="700px"
            style="border: none; overflow: hidden;"
            scrolling="no"
            ref="streamlitIframe"
            referrerpolicy="no-referrer"
          ></iframe> -->
        </div>
      </b-col>
    </b-row>
  </section>
  </template> 
  
  <script>
import { BRow, BCol } from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
  },
  mounted() {
    // this.setupIframe();
  },
  methods: {
    // setupIframe() {
    //   const iframe = this.$refs.streamlitIframe;
    //   iframe.onload = () => {
    //     iframe.contentWindow.postMessage(
    //       { type: 'resize', height: document.body.scrollHeight },
    //       '*'
    //     );
    //   };
    // },
  },
};
</script>
  