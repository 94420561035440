import Keycloak from 'keycloak-js'

const keycloakConfig = {
  url: "https://auth.mutuapps.com:8443",
  realm: "mutuapps-dev",
  clientId: "mutuapps-schedule-web"
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
