import Vue from 'vue'
import VueLazyload from 'vue-lazyload';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import useJwt from '@/auth/jwt/useJwt'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

import './registerServiceWorker'

import keycloak from "./keycloak";
import moduleApi from '@/store/module'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
require('@core/assets/fonts/feather/iconfont.css')

// import core styles
require('@core/scss/core.scss')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '',
  loading: '',
  attempt: 1
});

Vue.config.productionTip = false

keycloak
  .init({
    onLoad: 'login-required',
    checkLoginIframe: false,
  })
  .then(authenticated => {
    if (!authenticated) {
      console.warn('User is not authenticated. Redirecting to login...')
      keycloak.login()
    } else { 
      // Informasi Detail User
      // console.log('User Info (Decoded Token):', keycloak.tokenParsed);

      // Jika profil pengguna dimuat
      // keycloak.loadUserProfile().then(profile => {
      //   console.log('User Profile:', profile.attributes.role_asset[0]);
                
      // }).catch(err => {
      //   console.error('Failed to load user profile', err);
      // });

      useJwt.setToken(keycloak.token)

      if(!localStorage.getItem('userData')){
        console.log('ulang')
        moduleApi.fetchDataUsersAndWarehouse(keycloak.tokenParsed.email)
        .then(response => {  
          localStorage.setItem('warehouse', JSON.stringify(response.data.data[0]))
          const userData = keycloak.tokenParsed
          userData.idInstansi = response.data.data[0].id_instansi
          localStorage.setItem('userData', JSON.stringify(userData))
        })
        .catch(error => {
          console.log(error)
        })
      }   
    }

    Vue.prototype.$keycloak = keycloak

    new Vue({
      router,
      store,
      i18n,
      render: h => h(App),
      created() {
        // if (this.$route.name !== 'auth-login') {
          require('@core/scss/base/colors.scss')
          require('@core/scss/base/themes/dark-layout.scss')
          require('@core/scss/base/themes/semi-dark-layout.scss')
        // }
      }
    }).$mount('#app')
  })
  .catch(error => {
    console.error('Failed to initialize Keycloak:', error)
  })
